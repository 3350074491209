.sectionText
    width: 100%
    padding: 1% 0%
    .rowOne
        width: 65%
        margin-bottom: 0%
        @media screen and (max-width: 600px)
            width: 90%
        img
            width: 20pt
        h1
            color: $color-secundario
            font-size: 17px
            font-weight: 400
            margin: 1% 0%
            text-align: left
        h2
            color: $color-secundario
            font-size: 17px
            font-weight: 700
            margin: 1% 0%
            text-align: left