@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
@import url("https://fonts.googleapis.com/icon?family=Material+Icons")

// Body
$body-bg: #f8fafc

// Colors Principales
$color-principal: #000000
$color-secundario: #171717
$color-terciario: #D8D8D8
$color-cuarto: #ffffff
$color-quinto: #F5F5F5
$color-sexto: #CED4DA
$color-septimo: #868E96
$color-octavo: #E1E3E4
$color-nueve: #2A8CFF


$color-20: #6c757d
// Colors
$blue: #3490dc
$indigo: #6574cd
$purple: #9561e2
$pink: #f66D9b
$red: #e3342f
$orange: #f6993f
$yellow: #ffed4a
$green: #38c172
$teal: #4dc0b5
$cyan: #6cb2eb
$black: #000000
$white: #ffffff

//fonts
$font-principal: 'Raleway', sans-serif
