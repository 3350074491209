@import url("../css/materialize.min.css")
@import url("../css/animate.css")

@import 'variables'
@import 'components/header'
@import 'components/index/list'
@import 'components/footer'

@import 'components/web/banner'
@import 'components/web/text'

@import 'components/web/form'

@import 'components/thanks/info'
@import 'components/login/form'

@import 'components/list/table'

@import 'components/print/banner'
@import 'components/print/form'
html
    line-height: 0 !important
    overflow: scroll
    -webkit-overflow-scrolling: touch
    overflow-x: hidden

*
    font-family: $font-principal

body
    background: #ffffff

.swal-button--confirm
  background: $color-principal !important

.swal-button:active
  background-color: $color-principal !important

.swal-button
  background-color: $color-principal !important
  color: #ffffff
  font-weight: bold

.swal-button--cancel
  color: $color-principal
  background-color: #ffffff !important
  border: 1px solid $color-principal !important
