.footer
    padding: 0.5% 0%
    margin-top: 2%
    margin-bottom: 2%
    @media screen and (max-width: 600px)
        text-align: center
        .ocult
            display: none
    position: relative
    .version
        position: absolute
        left: 1%
        bottom: -20%
    .rowOne
        border-top: 1px solid $color-terciario
        width: 85%
        padding-top: 1%
        margin-bottom: 0% !important
        .row
            width: 100%
            margin: 0% !important
            h1
                color: $color-principal
                margin: 4% 0%
                font-size: 16px
                font-weight: 700
                @media screen and (max-width: 600px)
                    margin: 2% 0%
            h2
                color: $color-principal
                margin: 8% 0%
                margin-bottom: 0%
                font-size: 16px
                font-weight: 400
                @media screen and (max-width: 600px)
                    margin: 2% 0%
            .h2
                position: relative
                margin: 0%
                img
                    width: 22pt
                    position: relative
                    top: 0px
                span
                    position: absolute
                    margin-left: 4px
                    top: 15%
                    @media screen and (max-width: 600px)
                        position: relative
                        top: -10px
.floatW
    position: fixed
    right: 1%
    bottom: 2%
    z-index: 100
    img
        width: 40pt