.sectionFormPrint
    width: 100%
    padding: 0%
    .rowOne
        width: 100%
    .element
        padding: 0% 0% !important
        .p_img_2
            margin: 8% 0% !important
        h1,h2,h3,h4,h5,input,textarea,p,label,span
            font-size: 20px !important
    [type="checkbox"]:not(:checked):disabled+span:not(.lever):before
        background-color: transparent !important
        width: 25px !important
        height: 22px !important
        border: 1px solid $color-septimo !important
    textarea
        height: auto !important
        overflow: hidden
        resize: none
    .materialize-textarea
        border: 1px solid #CED4DA
        border-radius: 4px
        padding: 5px 10px
        background: #ffffff