.sectionThanks
    width: 100%
    padding: 1% 0%
    .rowOne
        width: 60%
        border-radius: 25px
        //-webkit-box-shadow: 0px 0px 18px 1px rgba(0,0,0,0.15)
        //-moz-box-shadow: 0px 0px 18px 1px rgba(0,0,0,0.15)
        //box-shadow: 0px 0px 18px 1px rgba(0,0,0,0.15)
        text-align: center
        padding: 5% 5%
        @media screen and (max-width: 600px)
            width: 90%
        img
            width: 150px     
            @media screen and (max-width: 600px)
                width: 100px   
        p
            text-align: justify
            font-size: 18px
            line-height: 22px
            font-weight: 500
            margin: 2% 0%
            @media screen and (max-width: 600px)
                font-size: 16px
                line-height: 20px
            span
                font-weight: 900
        .btn
            background: $color-principal
            color: $white
            font-size: 15px
            font-weight: 900
            border-radius: 100px
            -webkit-box-shadow: none
            -moz-box-shadow: none
            box-shadow: none
            width: auto
            text-transform: inherit
            @media screen and (max-width: 600px)
                font-size: 13px