.sectionIndexView
    width: 100%
    padding: 1% 0%
    padding-bottom: 0%
    .rowOne
        width: 90%
        margin-bottom: 0% !important
        .row
            width: 100%
            margin: 0% !important
        h1
            font-size: 30px
            font-weight: 500
            color: $color-principal
            text-transform: uppercase
            margin: 3% 0%
        .btn
            border-radius: 10px
            margin-top: 3%
            background: $color-cuarto
            font-size: 17px
            font-weight: 700
            color: $color-principal
            padding: 0% 6%
            -webkit-box-shadow: none
            -moz-box-shadow: none
            box-shadow: none
            width: 100%
            height: 35px
            line-height: 35px
            text-transform: inherit
            i
                font-size: 23pt
        table
            thead,tbody
                tr,th,td
                    border-radius: 0px
                    color: $color-cuarto
                    font-weight: 900
                    text-align: center
                    letter-spacing: 2px
                tr
                    border-bottom: none
                    border-radius: 10px
                    background: $color-principal
                th
                    padding: 20px 5px
                .border-tr
                    border-radius: 100px 0px 0px 100px
                .border-th
                    border-radius: 0px 100px 100px 0px
            tbody
                tr,td
                    background: $color-cuarto
                    color: $color-principal
                    font-weight: 400
                    text-align: center
                    letter-spacing: 0px
                    td
                        padding: 10px 5px
                        text-align: center
                        font-size: 15px
                        font-weight: 700
                        line-height: initial
                tr
                    &:hover
                        td
                            background: rgba(0, 0, 0, 0.1)
                    &.search-table-input
                        background: inherit !important
                        th
                            padding: 5px 5px !important
                        input,select
                            display: inherit
                            width: 100%
                            border: 1px solid $color-20
                            font-size: 14px
                            color: $color-principal
                            font-weight: 400
                            text-indent: 10px
                            border-radius: 5px
                            height: 25px
                            line-height: 25px
                            background: $color-cuarto
                            letter-spacing: 0px
                            margin-bottom: 0%
                            @media screen and (max-width: 600px)
                                width: 100%
                            &:focus
                                border: 1px solid $color-20 !important
                                -webkit-box-shadow: none !important
                                -moz-box-shadow: none !important
                                -o-box-shadow: none !important
                                box-shadow: none !important
                            &::placeholder
                                opacity: 1
                                font-size: 14px
                                color: $color-principal
                                letter-spacing: 0px
                        select
                            height: 30px
                            line-height: 30px
                        &:hover
                            background: inherit !important
                            cursor: inherit !important
                            td,a
                                color: $color-quinto !important
                a
                    &:hover
                        cursor: pointer
                    i
                        color: $color-principal
                        font-size: 20pt
        .pagination
            padding: 0%
            margin: 0%
            i
                color: $color-principal
            li
                //background: $color-20
                &:hover
                    cursor: pointer
            li.active
                background: $color-principal !important
                //padding: 0px 10px !important
                //position: relative
                //top: 3px
                span
                    position: relative
                    top: -4px