.header
    width: 80%
    margin: 0% 10%
    position: relative
    margin-top: 1%
    background: $color-cuarto
    border-radius: 21px
    box-shadow: 0px 6px 15px 3px rgba(0,0,0,0.05) !important
    -webkit-box-shadow: 0px 6px 15px 3px rgba(0,0,0,0.05) !important
    -moz-box-shadow: 0px 6px 15px 3px rgba(0,0,0,0.05) !important
    -o-box-shadow: 0px 6px 15px 3px rgba(0,0,0,0.05) !important
    @media screen and (max-width: 600px)
        width: 100%
        margin: 0%
        border-radius: 0px
    img
        position: relative
        top: 6px
        @media screen and (max-width: 600px)
            width: 100px