.sectionBanner
    width: 100%
    padding: 2% 0%
    .rowOne
        width: 65%
        margin-bottom: 0%
        @media screen and (max-width: 600px)
            width: 90%
        h1
            color: $color-principal
            font-size: 50px
            font-weight: 700
            margin: 1% 0%
            text-align: left
            margin-top: 30%
            @media screen and (max-width: 600px)
                text-align: center
                margin: 1% 0%
                font-size: 25px
        img
            width: 100%
            border-radius: 40px
        .element
            width: 100%
            background-repeat: no-repeat
            background-size: cover
            background-position: 50% center
            padding: 10% 4%
            position: relative
            border-radius: 30px
            transition: transform 0.5s
            @media screen and (max-width: 600px)
                border-radius: 10px
                padding: 25% 4%
            .back
                width: 100%
                height: 100%
                top: 0%
                left: 0%
                position: absolute
                background: rgba(0, 0, 0, 0.4)
                border-radius: 30px
                @media screen and (max-width: 600px)
                    border-radius: 10px
            h2
                color: $color-cuarto
                font-size: 25px
                font-weight: 700
                margin: 0%
                text-align: center
                position: absolute
                width: 100%
                top: 45%
                @media screen and (max-width: 600px)
                    font-size: 20px