.sectionForm
    width: 100% 
    padding: 2% 0%
    padding-top: 0%
    .rowOne
        width: 65% 
        margin-bottom: 0%
        @media screen and (max-width: 600px)
            width: 95%
        .element
            margin: 1% 0%
            background: $color-quinto
            padding: 3% 8%
            width: 100%
            @media screen and (max-width: 600px)
                padding: 5% 2% 
            .row
                width: 100%
                margin: 0% !important
                h3
                    color: $color-secundario
                    font-size: 16px
                    font-weight: 700
                    margin: 1% 0%
                    text-align: left
                    span
                        color: $color-nueve
                .h3
                    margin: 2% 0%
                h4
                    color: $color-secundario
                    font-size: 14px
                    font-weight: 500
                    margin: 1% 0%
                    text-align: left
                h5
                    color: $color-secundario
                    font-size: 16px
                    font-weight: 400
                    margin: 1% 0%
                    text-align: left
                    span
                        font-weight: 700
                    .span_2
                        color: $color-nueve
                        font-weight: 400
                input,textarea,select
                    height: 35px
                    line-height: 35px
                    border: 1px solid $color-sexto
                    border-radius: 4px
                    text-indent: 10px
                    background: $color-cuarto
                    font-size: 15px
                    @media screen and (max-width: 600px)
                        height: 30px
                        line-height: 30px
                    &:focus
                            border: 1px solid $color-sexto !important
                            -webkit-box-shadow: none !important
                            -moz-box-shadow: none !important
                            box-shadow: none !important
                    &::placeholder
                        font-size: 15px
                        opacity: 1 !important
                        color: $color-septimo
                        font-weight: 400
                textarea
                    text-indent: 0px
                    height: 80px
                    padding: 0px
                    line-height: 20px
                    padding: 10px
                p
                    margin: 2% 0%
                    @media screen and (max-width: 600px)
                        margin: 2% 0%
                    span
                        color: $color-secundario
                        font-weight: 400
                        position: relative
                        top: -5px
                    [type="checkbox"].filled-in:not(:checked)+span:not(.lever):after
                        border: 1px solid $color-octavo
                        border-radius: 3px
                        background-color: $color-octavo
                    [type="checkbox"].filled-in:checked+span:not(.lever):after
                        background-color: $color-nueve
                        border: 2px solid $color-nueve
                    [type="checkbox"].filled-in:not(:checked)+span:not(.lever):after,[type="checkbox"].filled-in:checked+span:not(.lever):after
                        height: 25px
                        width: 25px
                    [type="checkbox"].filled-in:checked+span:not(.lever):before
                        top: -2px
                        left: 2px
                        width: 9px
                        height: 18px
                    [type="checkbox"].filled-in:disabled:not(:checked)+span:not(.lever):after
                        background-color: $color-cuarto
                        border: 1px solid $color-septimo
                    [type=checkbox].filled-in:disabled:not(:checked) + span:not(.lever):after
                        border: 1px solid $color-octavo
                        border-radius: 3px
                        background-color: $color-octavo
                .p_img
                    margin: 5% 0%
                    margin-bottom: 0%
                    img
                        width: 150px
                        position: relative
                        top: -5px
                .p_img_2
                    margin: 14% 0%
                    img
                        width: 100px
                        position: absolute
                        left: 110%
                        top: -80%
                    .img_2
                        width: 60px
                        left: 135%
                    .img_3
                        width: 45px
                        left: 140%
                    .img_4
                        width: 80px
                        top: -60%
                .p_img_2_initial
                    margin-top: 8%
                .p_img_2_finally
                    margin-bottom: 5%
        .button
            text-align: center
            .btn
                margin-top: 1%
                box-shadow: none !important
                width: auto
                padding: 0% 8%
                background: $color-secundario
                border-radius: 5px
                font-size: 16px
                font-weight: 400
                color: $color-cuarto
                text-transform: inherit