.sectionList
    width: 100%
    padding: 2% 0%
    .rowOne
        width: 70%
        margin-bottom: 0%
        @media screen and (max-width: 600px)
            width: 90%
        .col
            text-align: center
        h1
            color: $color-principal
            font-size: 20px
            font-weight: 700
            margin: 1% 0%
            text-align: center
            @media screen and (max-width: 600px)
                font-size: 18px
        .h1
            @media screen and (max-width: 600px)
                margin-top: 10%
        img
            width: 100%
            border-radius: 40px
        .btn
            margin-top: 3%
            box-shadow: none !important
            width: auto
            padding: 0% 4%
            background: $color-secundario
            border-radius: 5px
            font-size: 18px
            font-weight: 400
            color: $color-cuarto
            text-transform: inherit
            @media screen and (max-width: 600px)
                font-size: 15px
        .element
            width: 100%
            background-repeat: no-repeat
            background-size: cover
            background-position: 50% center
            padding: 25% 4%
            position: relative
            border-radius: 30px
            transition: transform 0.5s
            @media screen and (max-width: 600px)
                margin-bottom: 4%
                border-radius: 10px
            &:hover
                transform: scale(1.15)
                z-index: 5
                box-shadow: 0px 6px 15px 3px rgba(0,0,0,0.05) !important
                -webkit-box-shadow: 0px 6px 15px 3px rgba(0,0,0,0.05) !important
                -moz-box-shadow: 0px 6px 15px 3px rgba(0,0,0,0.05) !important
                -o-box-shadow: 0px 6px 15px 3px rgba(0,0,0,0.05) !important
            .back
                width: 100%
                height: 100%
                top: 0%
                left: 0%
                position: absolute
                background: rgba(0, 0, 0, 0.4)
                border-radius: 30px
                @media screen and (max-width: 600px)
                    border-radius: 10px
            h2
                color: $color-cuarto
                font-size: 20px
                font-weight: 700
                margin: 0%
                text-align: center
                position: absolute
                width: 100%
                top: 45%