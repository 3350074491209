.sectionFormLogin
    width: 100%
    .rowOne
        width: 80%
        @media screen and (max-width: 600px)
            width: 95%
        img
            width: 100%
        .row
            width: 100%
            margin: 0% !important
            padding: 7% 10% !important
            @media screen and (max-width: 600px)
                padding: 0% !important
            h1
                margin: 4% 0%
                color: $color-principal
                font-weight: 700
                font-size: 25px
                text-align: center
                @media screen and (max-width: 600px)
                    font-size: 30px
                hr
                    border: 0
                    height: 4px
                    width: 10%
                    background: $color-principal
                    margin: 1% 0%
                    text-align: center
                    margin-left: auto !important
                    margin-right: auto !important
                    display: block !important
                    vertical-align: middle !important
                    @media screen and (max-width: 600px)
                    width: 10%
            h6
                font-size: 15px
                font-weight: 700
                color: $color-principal
                margin: 2% 0%
                text-align: left
            .input-field
                margin: 0% !important
                padding: 1% 3% !important
                text-align: center
                overflow: hidden
                @media screen and (max-width: 600px)
                    padding: 1% 0% !important
            input
                background: $white
                width: 100%
                text-indent: 10px
                color: $black
                font-weight: 700
                border-radius: 10px
                height: 3rem
                margin: 0%
                border: 1px solid $color-principal
                @media screen and (max-width: 600px)
                    width: 98%
                &:focus
                    border-bottom: 1px solid $color-principal !important
                    -webkit-box-shadow: none !important
                    box-shadow: none !important
            h5
                font-size: 15px
                color: $color-principal
                font-weight: 500
                &:hover
                    cursor: pointer
                    color: $color-principal
            .boton
                text-align: center
                .btn
                    border-radius: 10px
                    margin-top: 3%
                    background: $color-principal
                    font-size: 17px
                    font-weight: 700
                    color: $color-cuarto
                    padding: 0% 6%
                    -webkit-box-shadow: none
                    -moz-box-shadow: none
                    box-shadow: none
                    width: 100%
                    height: 3rem
                    line-height: 3rem